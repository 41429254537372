<template>
  <div class="box">
    <!--        问题总结 教室评语-->
    <el-row :gutter="20">
      <el-col :span="12"><div class="sums">
        <div>问题总结</div>
        <div class="remark-p">
          <p>{{ problem }}</p>
        </div>
      </div></el-col>
      <el-col :span="12">
        <div class="remark">
          <div class="danger">老师评语</div>
          <div class="remark-p">
            <p>{{ comment }}</p>
          </div>

        </div></el-col>
    </el-row>

    <el-table
      :v-loading="loading"
      :data="homeworkList"
      :header-cell-style="{textAlign:'center',fontSize: '16px',fontWeight:500,color:'#000'}"
      :row-style="{marginTop:'16px',borderSpacing:'20px'}"
      :cell-style="{textAlign:'center',color:'#000'}"
      style="width: 100%;
                     background: #edf1f7;
                    border-collapse: separate !important;
                    border-spacing: 10px !important;
                    "
    >
      <el-table-column
        fixed
        type="index"
        :index="indexMethod"
        label="题号"
        width="140"
      />
      <el-table-column
        fixed
        prop="questionName"
        :show-overflow-tooltip="true"
        label="题目名称"
        width="350"
      />
      <el-table-column
        prop="questionType"
        label="题型"
        :formatter="typeFormat"
        width="140"
      />
      <el-table-column
        prop="type"
        label="类型"
        :formatter="categoryFormat"
        width="140"
      />
      <el-table-column
        prop="level"
        label="难度"
        :formatter="levelFormat"
        width="140"
      />
      <el-table-column
        prop="status"
        label="结果"
        :formatter="statusFormat"
        width="140"
      />
      <el-table-column
        fixed="right"
        label="操作"
        prop="status"
        width="140"
      >
        <template slot-scope="scope">
          <!--                    <el-button @click="changeStatus(scope.row)" type="text" size="small">{{scope.row.status===0?'未完成':'已完成'}}</el-button>-->
          <!--                            <el-button type="text" size="small" @click="getDetail(scope.$index)">查看</el-button>-->
          <el-button type="text" size="small" @click="goDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--翻页-->
    <div class="changePage">
      <el-pagination
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="45%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <section>
        <h3>作业题目</h3>
        <div style="text-align: left;padding: 10px" v-html="currentHomework.questionInformation" />
        <div class="text-right">结果：
          <span v-if="currentHomework.questionType===1||currentHomework.questionType===0" class="blue">
            {{ currentHomework.subjectResults===null?'暂无':task_subject_results[currentHomework.subjectResults].label }}
          </span>
          <span v-else class="blue">
            {{ currentHomework.results===0?'正确':currentHomework.results===1?'错误':'暂无' }}
          </span>
        </div>
      </section>
      <section>
        <h3>答案信息</h3>
        <div style="text-align: left;padding: 10px" v-html="currentHomework.studentAnswer" />

      </section>
      <section>
        <h3>正确答案</h3>
        <div style="text-align: left;padding: 10px" v-html="currentHomework.answerInformation" />
      </section>
      <section v-if="currentHomework.questionType===1||currentHomework.questionType===0">
        <h3>作业点评</h3>
        <div style="text-align: left;padding: 10px">{{ currentHomework.review }}</div>
      </section>
      <div v-if="currentHomework.questionType===1||currentHomework.questionType===0" class="text-left"> <el-button type="text" @click="downLoadAnswer">下载附件</el-button></div>

    </el-dialog>

  </div>
</template>

<script>
import { getFeedback, getStudentInfoList } from '../../api/course'
import { mapState } from 'vuex'

export default {
  name: 'HomeworkSubmitted',
  created() {
    console.log('1234')
    this.taskLogId = this.$route.query.id
    this.onLoad()
  },
  computed: {
    ...mapState({
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr'],
      task_subject_results: state => state.dict.dictTree.task_subject_results['arr']
    })
  },
  props: {
    tableData: {
      type: [Object, Array],
      default() {
        return [
          {}
        ]
      }
    }
  },
  data() {
    return {
      publishId: '',
      taskLogId: '',
      total: 0,
      dialogVisible: false,
      loading: false,
      homeworkList: [],
      problem: '',
      comment: '',
      currentPage: 1,
      pageSize: 10,
      currentPn: 1,
      currentHomework: {}
    }
  },
  methods: {
    onLoad() {
      this.getHomeworkDetail()
    },
    indexMethod(index) {
      return index + this.currentPn
    },
    // 详情
    getHomeworkDetail() {
      getStudentInfoList({ taskLogId: this.taskLogId, pageSize: this.pageSize, pageNum: this.currentPage }).then(res => {
        this.total = res.total
        this.homeworkList = res.rows
        this.publishId = res.rows[0].classPublishId
        if (res.total !== 0) {
          getFeedback({ id: this.publishId }).then(res => {
            console.log(res)
            this.problem = res.data.taskFeedback
            this.comment = res.data.comment
          })
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.currentPn = this.pageSize * (this.currentPage - 1) + 1
      this.onLoad()
    },
    goDetail(data) {
      //    弹框
      console.log(data)
      this.currentHomework = data
      this.dialogVisible = true
    },
    typeFormat(row) {
      let name = ''
      this.ea_question_type.forEach(function(item) {
        if (row.questionType == item.value) {
          name = item.label
        }
      })
      return name
    },
    levelFormat(row) {
      let name = ''
      this.ea_question_difficulty.forEach(function(item) {
        if (row.level == item.value) {
          name = item.label
        }
      })
      return name
    },
    categoryFormat(row) {
      let name = ''
      this.ea_question_category.forEach(function(item) {
        if (row.type == item.value) {
          name = item.label
        }
      })
      return name
    },
    statusFormat(row) {
      let name = ''
      if (row.questionType === 1 || row.questionType === 0) {
        if (row.subjectResults === null || row.subjectResults === undefined) {
          name = '暂无'
        } else {
          name = this.task_subject_results[row.subjectResults].label
        }
      } else {
        name = row.results === 0 ? '正确' : row.results === null ? '暂无' : '错误'
      }
      return name
    },
    // 关闭之前
    handleClose() {
      this.dialogVisible = false
    },
    // 下载附件
    downLoadAnswer() {
      if (this.currentHomework.answerUrls == '' || this.currentHomework.answerUrls == null || this.currentHomework.answerUrls == undefined) {
        this.$message.warning('该试题答案没有附件')
      } else {
        this.$download.name(this.currentHomework.answerUrls)
      }
    }
  }
}
</script>

<style scoped lang="less">
    /deep/ .el-pagination.is-background .el-pager li{
        background: white;
    }
    .sums,.remark{
        background: white;
        margin-bottom: 20px;
        padding: 20px;
        height: 250px;

      .remark-p{
        color: black;
        font-size: 16px;
        height: 150px;
        overflow-y: auto;
        line-height: 25px;
        padding: 10px;
      }

        .danger{
            color: rgba(255, 47, 0, 1);
        }
        div{
            color: #66b1ff;
            font-size: 18px;
            margin-bottom: 20px;
        }
        p{
            text-align: left;
        }
    }
    /* 定义滚动条样式 */
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: rgba(240, 240, 240, 1);
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
      border-radius: 10px;
      background-color: rgba(240, 240, 240, .5);
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
      background-color: rgba(240, 240, 240, .5);
    }
    .box /deep/ .el-dialog{
        min-height: 200px;
    }
    /deep/ .el-dialog__header{
        padding: 0;
    }
    .text-left{
        text-align: left;
    }
    section{
        margin-bottom: 20px;
        h3{
            font-weight: 500;
            font-size: 18px;
            text-align: left;
        }
        p{
            margin: 10px 0;
            text-align: left;
        }
    }
    .blue{
        color: #66b1ff;
    }
    .text-right{
        text-align: right;
    }
    .changePage{
      margin: 10px 0 20px 0;
        text-align: right;
    }
    /deep/ .el-table__body{
        border-collapse: separate;
        border-spacing:0 10px;
    }
    .box{
        width: 1200px;
        margin: auto;
    }
</style>
